<template>
  <!--    class="navbar navbar-expand-lg navbar-dark bg-light fixed-top navbar-not-homepage"-->
  <nav
    id="topbar"
    :class="{
      'navbar navbar-expand-lg navbar-dark fixed-top navbar-not-homepage': true,
      'bg-seeker': $props.role == 'seeker',
      'bg-traveller': $props.role == 'traveller',
      'bg-secondary': $props.role == 'partner',
    }"
  >
    <div class="container-fluid">
      <a
        class="navbar-brand"
        href="/"
      >
        <img
          src="/img/logo-color.png"
          alt="Trepr"
          title="Trepr"
          width="150"
          height="49"
        >
      </a>
      <button
        class="navbar-toggler bg-secondary"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div
        id="navbarToggler"
        class="collapse navbar-collapse"
      >
        <ul
          class="navbar-nav navbar-top ms-auto mb-2 mb-lg-0 navbar-center text-primary navbar-seeker-traveller"
        >
          <li class="nav-item nav-item-currency">
            <a
              class="nav-link"
              href="#"
            > GBP - £</a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
            > Help</a>
          </li>
          <LiLoggedin v-if="null != $store.state.token" />
        </ul>
      </div>
    </div>
  </nav>
  <div
    v-if="$store.state.role == 'seeker' || $store.state.role == 'traveller'"
    class="role-switch-block"
  >
    <div class="select-option-box">
      <div class="btn-box seeker-box bg-seeker">
        <router-link
          :to="'/seeker/' + switchPage"
          class="text-dark"
          @click="switchToSeeker"
        >
          <button
            :class="{
              btn: true,
              'btn-seeker': true,
              'w-100': true,
              'h-100': true,
            }"
          >
            Seeker
          </button>
        </router-link>
      </div>
      <div class="sep">
        <span>Or</span>
      </div>

      <div class="btn-box traveller-box bg-traveller">
        <router-link
          :to="'/traveller/' + switchPage"
          class="text-white"
          @click="switchToTraveller"
        >
          <button
            :class="{
              btn: true,
              'w-100': true,
              'h-100': true,
            }"
          >
            Traveller
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import  "bar/TopbarLoggedIn"
import { mapGetters } from 'vuex';

// import TopbarLoggedIn from "./bar/TopbarLoggedIn"
import LiLoggedin from './bar/LiLoggedin';
export default {
  name: 'HeaderLoggedIn',
  components: { LiLoggedin },
  props: ['role'],
  methods: {
    switchToSeeker: function () {
      this.$store.commit('changeCurrentRole', 'seeker');
    },
    switchToTraveller: function () {
      this.$store.commit('changeCurrentRole', 'traveller');
    },
  },
  computed: {
    ...mapGetters({
      state: 'getState',
    }),
    switchPage: function () {
      let page = this.$route.fullPath.split('/').pop();
      console.log('page ', page);
      if (page == 'traveller-request') return 'seeker-request';
      if (page == 'seeker-request') return 'traveller-request';
      return page;
    },
  },
};
</script>

<style scoped></style>
