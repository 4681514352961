<template>
  <div
    id="main-left"
    :class="{
      'main-left-offcanvas': true,
      'bg-seeker': $props.role == 'seeker',
      'bg-traveller': $props.role == 'traveller',
    }"
  >
    <ul v-if="state.role !== undefined">
      <li
        class="left-menu-item active"
        style=""
      >
        <router-link
          :to="'/' + $props.role + '/dashboard'"
          class="left-menu-item-link"
        >
          <span class="left-menu-item-icon"><i class="fa fa-home" /></span>
          <span class="left-menu-item-text">Dashboard</span>
        </router-link>
      </li>
      <li
        class="left-menu-item"
        style=""
      >
        <router-link
          :to="'/' + $props.role + '/my-travel-need'"
          class="left-menu-item-link"
        >
          <span class="left-menu-item-icon"><i class="fa fa-calendar" /></span>
          <span class="left-menu-item-text">My Travel Needs</span>
        </router-link>
      </li>
      <li
        class="left-menu-item"
        style=""
      >
        <router-link
          :to="'/' + $props.role + '/search'"
          class="left-menu-item-link"
        >
          <span class="left-menu-item-icon"><i class="fa fa-search" /></span>
          <span class="left-menu-item-text">Search</span>
        </router-link>
      </li>
      <!--            <li class="left-menu-item" style="">-->
      <!--                <a href="#" class="left-menu-item-link">-->
      <!--                    <span class="left-menu-item-icon"><i class="fa fa-plus"></i></span>-->
      <!--                    <span class="left-menu-item-text">Add Travel Needss</span>-->
      <!--                </a>-->
      <!--                <ul class="left-menu-item-submenu" style="">-->
      <!--                    <li>-->
      <!--                        <router-link :to="{ name: 'layout', params: {role: $props.role, page: 'add-people-service'}}" >-->
      <!--                            <span class="left-menu-item-text">People Service </span>-->
      <!--                        </router-link>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                        <router-link :to="{ name: 'layout', params: {role: $props.role, page: 'add-package-server'}}" >-->
      <!--                            <span class="left-menu-item-text"> Package Service </span>-->
      <!--                        </router-link>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                        <router-link :to="{ name: 'layout', params: {role: $props.role, page: ''}}" >-->
      <!--                            <span class="left-menu-item-text"> Product Service</span>-->
      <!--                        </router-link>-->
      <!--                    </li>-->
      <!--                </ul>-->
      <!--            </li>-->
      <li
        class="left-menu-item"
        style=""
      >
        <router-link
          v-if="$props.role == 'seeker'"
          :to="'/' + $props.role + '/traveller-request'"
          class="left-menu-item-link"
        >
          <span class="left-menu-item-icon"><i class="fa fa-download" /></span>
          <span class="left-menu-item-text">Traveller Request</span>
        </router-link>

        <router-link
          v-else
          :to="'/' + $props.role + '/seeker-request'"
          class="left-menu-item-link"
        >
          <span class="left-menu-item-icon"><i class="fa fa-download" /></span>
          <span class="left-menu-item-text">Seeker Request</span>
        </router-link>
      </li>
      <!--            <li class="left-menu-item " style="">-->
      <!--                <router-link :to="{ name: 'layout', params: {role: $props.role, page: 'my-traveller-wish-list'}}" class="left-menu-item-link">-->
      <!--                    <span class="left-menu-item-icon"><i class="fa fa-heart"></i></span>-->
      <!--                    <span class="left-menu-item-text">My Traveler Wish List</span>-->
      <!--                </router-link>-->
      <!--            </li>-->

      <!--            <li class="left-menu-item" style="">-->
      <!--                <router-link :to="{ name: 'layout', params: {role: $props.role, page: 'my-service-expenses'}}" class="left-menu-item-link">-->
      <!--                    <span class="left-menu-item-icon"><i class="fa fa-money-check-alt"></i></span>-->
      <!--                    <span class="left-menu-item-text">My Service Expenses</span>-->
      <!--                </router-link>>-->
      <!--            </li>-->
      <!--            <li class="left-menu-item " style="">-->
      <!--                <router-link :to="{ name: 'layout', params: {role: $props.role, page: 'travel-need-history'}}" class="left-menu-item-link">-->
      <!--                    <span class="left-menu-item-icon"><i class="fa fa-history"></i></span>-->
      <!--                    <span class="left-menu-item-text">Travel Need History</span>-->
      <!--                </router-link>>-->
      <!--            </li>-->
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LeftbarLoggedIn',
  props: ['role'],
  computed: {
    ...mapGetters({
      state: 'getState',
    }),
    // currentPage : function () {
    //     return this.$router.currentRoute._rawValue.params.page
    // }
  },
};
</script>

<style scoped></style>
