<template>
  <div class="main-right-content-breadcrumb">
    <div class="left-offcanvas-toggle-icon">
      <i class="fa fa-bars" id="openMenu" @click="openMenuClick"></i>
      <i
        class="fa fa-info-circle d-lg-none"
        id="openInfo"
        @click="openInfoClick"
        v-if="txtCurrentPage == 'search'"
      ></i>
      <i
        class="fa fa-filter d-lg-none text-primary"
        id="openFilter"
        @click="openFilterClick"
        v-if="txtCurrentPage == 'search'"
      ></i>
    </div>
    <div class="main-right-content-breadcrumb-wrapper d-inline-block">
      <nav aria-label="breadcrumb" class="pt-3">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home </a></li>
          <li class="breadcrumb-item active text-capitalize" aria-current="page">
            {{ txtCurrentPage }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
const $ = jQuery.noConflict();
//TODO: replace jquery with vuejs
export default {
  name: 'Breadcrumb',
  props: ['page'],
  methods: {
    openMenuClick: function () {
      $('#main-left').toggleClass('main-left-offcanvas-expanded');
      $('#openMenu').toggleClass('text-primary');
      if ($(window).width() <= 991) {
        $('#main-right-content-breadcrumb-wrapper').toggleClass('d-none d-inline-block');
      }
    },
    openInfoClick: function () {
      $('#search-header-wrapper').toggleClass('d-none');
      $('#openInfo').toggleClass('text-primary');
    },
    openFilterClick: function () {
      $('#search-block-input-wrapper').toggleClass('d-none');
      $('#btnSearch').toggleClass('d-none');
      $('#openFilter').toggleClass('text-primary');
    },
  },
  computed: {
    txtCurrentPage: function () {
      let page = window.location.pathname.split('/').pop().replaceAll('-', ' ');
      // let tmp = this.$props.page.page.replaceAll("-", " ");
      return page;
    },
  },
};
</script>

<style scoped></style>
